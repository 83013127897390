<template>
  <v-card class="ml-13" flat height="100vh">
    <div style="z-index: 100 !important" v-if="vacanciesStateLoading === true">
      <transition name="modal-fade">
        <div class="modal-backdrop" style="z-index: 100 !important">
          <div class="modal" role="dialog" area-labelledby="modalTitle" aria-describedby="modalDesc">
            <v-col cols="10">
              <h4 class="mb-4 font-weight-regular">espere un momento...</h4>
              <v-progress-linear color="#6E80F8" indeterminate rounded height="6"></v-progress-linear>
            </v-col>
          </div>
        </div>
      </transition>
    </div>
    <v-container fluid grid-list class="ma-0 pa-0">
      <v-layout wrap d-flex justify-start class="ma-0 pa-0">
        <v-flex xs12 sm12 md2>
          <v-card flat tile height="100vh">
            <ZoneSelector />
          </v-card>
        </v-flex>
        <v-flex xs12 sm12 md10>
          <v-card tile height="100vh" color="#F5F5F5" class="pa-3" elevation="0">
            <v-card elevation="0">
              <v-card-title class="d-flex align-center pt-8 pb-6">
                <div class="d-flex align-center">
                  <v-icon color="#A3AEFF" class="mr-3">mdi-home-circle</v-icon>
                  <p class="text-body-1 ma-0 font-weight-bold">Vacantes</p>
                  <v-icon class="mr-2 ml-10" small color="#6F50FF">mdi-help-circle-outline</v-icon>
                  <p class="text-caption ma-0 mr-10">
                    Crea y administra las vacantes que tu empresa necesita
                  </p>
                </div>
                <div class="d-flex align-center">
                  <p class="text-caption ma-0 font-weight-bold pt-1 pb-1 pl-2 pr-2 rounded"
                    style="background: #f2f4f7; color: #a4afc0">
                    {{ avaliableVacancies() }}
                  </p>
                  <v-divider vertical class="mx-3"></v-divider>
                  <p v-if="numerOfVacancies !== 0" class="text-caption ma-0 font-weight-light">
                    Vacantes Disponibles
                  </p>
                  <p v-else class="text-caption ma-0 font-weight-light red--text font-weight-medium">
                    Sin Vacantes Disponibles
                  </p>

                  <v-btn small :disabled="numerOfVacancies === 0" color="#8739EB" rounded outlined
                    class="text-none ml-10" to="/new-vacancy">
                    <v-icon small class="mr-1">mdi-plus-circle-outline</v-icon>
                    Nueva Vacante
                  </v-btn>
                </div>
              </v-card-title>
              <v-divider></v-divider>
              <!-- <v-card outlined tile height="80vh" elevation="0">
                <Table />
              </v-card> -->
              <v-card class="ml-3 mt-5" elevation="0">
                <v-tabs v-model="tab" grow color="#8739EB" class="d-flex">
                  <v-tab class="text-none caption font-weight-bold" @click="SET_ACTIVE_VACANCIES()">
                    Activas
                  </v-tab>
                  <v-tab class="text-none caption font-weight-bold" @click="SET_ARCHIVE_VACANCIES()">
                    Archivadas
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" class="mt-4">
                  <v-tab-item>
                    <Table />
                  </v-tab-item>
                  <v-tab-item>
                    <Table />
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-card>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

  </v-card>
</template>

<script>
import Table from "../../components/Vacancies/Table.vue";
import ZoneSelector from "../../components/App/ZoneSelector.vue";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import ENUM from "@/store/enums";

export default {
  components: {
    Table,
    ZoneSelector,
  },
  data() {
    return {
      maximum_vacancies: 0,
      vancancies_created: 0,
      numerOfVacancies: 0,
      tab: null,
    };
  },

  methods: {
    ...mapActions(["requestsAllCompanies", "changeArchiveState"]),
    ...mapMutations(["SET_ARCHIVE_VACANCIES", "SET_ACTIVE_VACANCIES"]),
    avaliableVacancies() {
      this.numerOfVacancies = this.maximum_vacancies - this.vancancies_created;
      return this.numerOfVacancies;
    },
  },
  computed: {
    ...mapGetters(["getCompany"]),
    ...mapState({
      vacanciesState: (state) => state.vacancies.status,
    }),
    vacanciesStateLoading() {
      return this.vacanciesState === ENUM.LOADING;
    },
  },

  watch: {
    getCompany() {
      this.maximum_vacancies = this.getCompany.company_maximum_vacancies;
      this.vancancies_created = this.getCompany.company_vacancies;
      this.numerOfVacancies =
        this.getCompany.company_maximum_vacancies -
        this.getCompany.company_vacancies;
    },
  },

  beforeCreate() {
    this.$store.dispatch("requestsAllCompanies");
  },
  beforeMount() {
    this.SET_ACTIVE_VACANCIES();
  },
};
</script>

<style>
.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100 !important;
}

.modal {
  width: 25vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center !important;
  background: transparent;
  box-shadow: 0;
  overflow: hidden;
}

.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

h4 {
  color: #fff;
  letter-spacing: 2px;
}
</style>
