<template>
  <div v-if="vacanciesStateLoaded === true" class="pb-5">

    <v-card v-if="vacanciesSelected === true" class="d-flex flex-row mt-2 mb-6" flat tile>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-5 ml-5" color="#F86E80" fab depressed x-small dark v-bind="attrs" v-on="on"
            @click="deleteVacancies()">
            <v-icon small>mdi-delete-outline</v-icon>
          </v-btn>
        </template>
        <span>Borrar</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-8" color="#AE5AEF" fab depressed x-small dark v-bind="attrs" v-on="on"
            @click="archiveVacancies()">
            <v-icon v-if="getArchive" small>mdi-archive-arrow-down-outline</v-icon>
            <v-icon v-else small>mdi-archive-arrow-up-outline</v-icon>
          </v-btn>
        </template>
        <span v-if="getArchive">Archivar</span>
        <span v-else>Desarchivar</span>
      </v-tooltip>
    </v-card>

    <div>
      <v-alert dense text type="success" transition="scale-transition" v-if="vacancyCreated">
        <p class="ma-0 pa-0 caption">¡Vacante creada con éxito!</p>
      </v-alert>
      <v-alert dense text type="success" transition="scale-transition" v-if="vacancyDeleted">
        <p class="ma-0 pa-0 caption">¡Se eliminó con éxito!</p>
      </v-alert>
      <v-alert dense text type="success" transition="scale-transition" v-if="vacancyArchived">
        <p class="ma-0 pa-0 caption">¡Se archivó con éxito!</p>
      </v-alert>
      <v-alert dense text type="success" transition="scale-transition" v-if="vacancyUnaArchived">
        <p class="ma-0 pa-0 caption">¡Se desarchivó con éxito!</p>
      </v-alert>
      <div class="my-4">
        <v-text-field v-model="search" color="#6200EE" class="caption shrink ml-2 pl-3 ma-0 pa-0 mb-2 text-capitalize"
          style="width: 100%; background: #f7f8fa" single-line hide-details>
          <template v-slot:label class="caption">
            <span class="caption ml-2">Buscar</span>
          </template>
        </v-text-field>
      </div>
      <div class="d-flex justify-end">
        <vue-excel-xlsx :data="getAllVacancies" :columns="columns" :file-name="'Vacantes'" :file-type="'xlsx'"
          :sheet-name="'sheetname'">
          <v-btn tile color="transparent" elevation="0" class="caption text-capitalize mr-3">
            <v-icon color="#74DEA4" x-small class="mr-1">mdi-download</v-icon>
            <p class="pa-0 ma-0" style="color: #74dea4">Descargar XLSX</p>
          </v-btn>
        </vue-excel-xlsx>
      </div>
      <v-data-table v-model="selected" :headers="headers" :items="allVacanciesFiltered" hide-default-footer
        :page.sync="page" :items-per-page="itemsPerPage" @page-count="pageCount = $event" item-key="id" show-select
        class="elevation-0">
        <template v-slot:[`item.name`]="{ item }">
          <v-hover v-slot="{ hover }">
            <v-skeleton-loader type="list-item" class="ma-0 pa-0" style="width: 12rem" :loading="loading">
              <router-link :to="{ path: 'vacancies/' + `${item.id}` }" style="text-decoration: none"
                @click.native="SELECT_VACANCY(`${item.id}`)">
                <v-layout justify-start class="font-weight-bold caption" :style="{ color: hover ? '#8739EB' : '#000' }">
                  {{ item.name }}
                </v-layout>
              </router-link>
            </v-skeleton-loader>
          </v-hover>
        </template>
        <template v-slot:[`item.availableVacancies`]="{ item }">
          <v-skeleton-loader type="list-item" class="ma-0 pa-0" :loading="loading" style="width: 4rem">
            <p class="caption ma-0 pa-0">{{ item.availableVacancies }}</p>
          </v-skeleton-loader>
        </template>
        <template v-slot:[`item.startDate`]="{ item }">
          <v-skeleton-loader type="list-item" class="ma-0 pa-0" :loading="loading" style="width: 5rem">
            <p class="caption ma-0 pa-0">{{ formatDate(item.startDate) }}</p>
          </v-skeleton-loader>
        </template>
        <template v-slot:[`item.endDate`]="{ item }">
          <v-skeleton-loader type="list-item" class="ma-0 pa-0" :loading="loading" style="width: 5rem">
            <p class="caption ma-0 pa-0">{{ formatDate(item.endDate) }}</p>
          </v-skeleton-loader>
        </template>
        <template v-slot:[`item.workHours`]="{ item }">
          <v-skeleton-loader type="list-item" class="ma-0 pa-0" :loading="loading" style="width: 4rem">
            <p class="caption ma-0 pa-0">{{ item.workHours }} hrs</p>
          </v-skeleton-loader>
        </template>
        <template v-slot:[`item.createdBy`]="{ item }">
          <v-skeleton-loader type="list-item" class="ma-0 pa-0" :loading="loading" style="width: 8rem">
            <p class="caption ma-0 pa-0">{{ item.createdBy }}</p>
          </v-skeleton-loader>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-skeleton-loader type="list-item" class="ma-0 pa-0" :loading="loading" style="width: 4rem">
            <v-chip x-small :text-color="getTextColor(item.status)" :color="getColor(item.status)">
              {{ capitalizeFirstLetter(item.status) }}
            </v-chip>
          </v-skeleton-loader>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <v-pagination elevation="0" v-model="page" :length="pageCount" class="custom caption mt-7" small color="#A3A1FB"
        circle></v-pagination>
    </div>
</div>
</template>

<script>
import LoadCard from "../../mixins/LoadCard";
import ENUM from "@/store/enums";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
  mixins: [LoadCard],
  data: () => ({
    alertText: "",
    loading: true,
    search: "",
    singleSelect: false,
    selected: [],
    headers: [
      { text: "Nombre", align: "start", value: "name", sortable: false },
      { text: "# Vacantes", value: "availableVacancies", align: "center" },
      // { text: "# Candidatos", value: "candidatesNumber" },
      { text: "Inicio", value: "startDate", align: "center" },
      { text: "Término", value: "endDate", align: "center" },
      { text: "Jornada", value: "workHours", align: "center" },
      { text: "Creado por", value: "createdBy", align: "center" },
      { text: "Estado", value: "status", align: "center" },
      // { text: "Reclutadores", value: "recruiters" },
    ],
    columns: [
      {
        label: "Nombre",
        field: "name",
      },
      {
        label: "Vacantes",
        field: "availableVacancies"
      },
      {
        label: "Inicio",
        field: "startDate"
      },
      {
        label: "Termino",
        field: "endDate"
      },
      {
        label: "Jornada",
        field: "workHours"
      },
      {
        label: "Creado por",
        field: "createdBy"
      },
      {
        label: "Estado",
        field: "status"
      }
    ],
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
  }),
  methods: {
    ...mapActions([
      "deleteVacanciesById",
      "requestAllVacancies",
      "archiveVacanciesById",
      "unarchiveVacanciesById",
      "requestsAllCompanies",
      "changeArchiveState",
    ]),
    ...mapMutations(["SELECT_VACANCY"]),
    getColor(status) {
      switch (status) {
        case "activa":
          return "#E5E7FB";
        case "inactiva":
          return "#DEF9F9";
        case "pendiente":
          return "#F2F4F7";
        case "concluida":
          return "#f7e1c3";
      }
    },
    getTextColor(status) {
      switch (status) {
        case "activa":
          return "#828AF6";
        case "inactiva":
          return "#16C4C0";
        case "pendiente":
          return "#A4AFC0";
        case "concluida":
          return "#e89423";
      }
    },
    deleteVacancies() {
      const vacanciesIds = [];
      this.selected.forEach((vacancy) => {
        vacanciesIds.push(vacancy.id);
      });
      this.deleteVacanciesById(vacanciesIds).then(() => {
        this.selected = [];
        setTimeout(() => {
          this.requestAllVacancies(this.selectedZone);
          this.requestsAllCompanies();
        }, 2000);
        setTimeout(() => this.$store.commit("ALERT_OK_DEL", false), 3000);
      });
    },
    archiveVacancies() {
      const isArchive = this.getArchive;
      const vacanciesIds = [];
      this.selected.forEach((vacancy) => {
        vacanciesIds.push(vacancy.id);
      });
      if (isArchive) {
        this.archiveVacanciesById(vacanciesIds).then(() => {
          this.selected = [];
          setTimeout(() => {
            this.requestAllVacancies(this.selectedZone);
          }, 2000);
          setTimeout(() => this.$store.commit("ALERT_OK_ED", false), 3000);
        });
      } else {
        this.unarchiveVacanciesById(vacanciesIds).then(() => {
          this.selected = [];
          setTimeout(() => {
            this.requestAllVacancies(this.selectedZone);
          }, 2000);
          setTimeout(() => this.$store.commit("ALERT_OK_DESA", false), 3000);
        });
      }
    },
    formatDate(item) {
      return moment(item).format("DD/MM/YYYY");
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    handleClick(value) {
      console.warn("handleClick: " + value);
    },
  },
  computed: {
    ...mapGetters(["getAllVacancies", "getArchive"]),
    ...mapState({
      vacanciesState: (state) => state.vacancies.status,
      selectedZone: (state) => state.vacancies.selectedZone,
    }),
    allVacanciesFiltered() {
      let VacanciesFiltered = this.getAllVacancies
      const input = this.search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
      if (input) {
        VacanciesFiltered = this.getAllVacancies.filter((item) => {
          const nameItem = item.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          return nameItem.includes(input)
        })
      } else {
        VacanciesFiltered = this.getAllVacancies
      }
      return VacanciesFiltered
    },
    vacanciesStateLoaded() {
      return this.vacanciesState === ENUM.LOADED;
    },
    vacanciesSelected() {
      return this.selected.length > 0 ? true : false;
    },
    vacancyCreated() {
      return this.$store.state.vacancies.alertOk;
    },
    vacancyDeleted() {
      return this.$store.state.vacancies.alertOkDel
    },
    vacancyArchived() {
      return this.$store.state.vacancies.alertOkEd;
    },
    vacancyUnaArchived() {
      return this.$store.state.vacancies.alertUnarchived
    }
  },
  watch: {
    vacancyCreated(vacancyCreated) {
      if (vacancyCreated) {
        return setTimeout(() => this.$store.commit("ALERT_OK", false), 3000);
      }
    },
  }
};
</script>

<style>
.v-pagination__item {
  height: 28px !important;
  min-width: 28px !important;
}

.v-pagination__navigation {
  height: 28px !important;
  width: 28px !important;
}

.v-input__control {
  height: 20px !important;
  font-size: 10px !important;
  flex-direction: row !important;
}
</style>
